import { type FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout, Loadable } from '@fleet/shared';
import { InventoryModelDetails } from 'routes/InventoryConfiguration/InventoryModel/InventoryModelDetails';
import { InventoryModelTable } from 'routes/InventoryConfiguration/InventoryModel/InventoryModelTable';
import { useSelector } from 'store/utils';
import { inventoryModelLoadingSelector } from 'features/loading/loadingSelectors';

export const InventoryModel: FC = () => {
  const loading = useSelector(inventoryModelLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        drawer={
          <Switch>
            <Route
              exact
              path="/configuration/model/:action(create|edit)/:id?"
              component={InventoryModelDetails}
            />
          </Switch>
        }
      >
        <InventoryModelTable />
      </Layout>
    </Loadable>
  );
};
