import { Layout, Loadable } from '@fleet/shared';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { InventoryBucketDetails } from 'routes/InventoryConfiguration/InventoryBucket/InventoryBucketDetails';
import { InventoryBucketTable } from 'routes/InventoryConfiguration/InventoryBucket/InventoryBucketTable';
import { useSelector } from 'store/utils';
import { inventoryBucketLoadingSelector } from 'features/loading/loadingSelectors';

interface InventoryBucketProps {}

export const InventoryBucket: FC<InventoryBucketProps> = () => {
  const loading = useSelector(inventoryBucketLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        drawer={
          <Switch>
            <Route
              path="/configuration/bucket/:action(create|edit)/:id?"
              component={InventoryBucketDetails}
              exact
            />
          </Switch>
        }
      >
        <InventoryBucketTable />
      </Layout>
    </Loadable>
  );
};
