import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { currentInventoryModelSelector } from 'features/inventoryModel/inventoryModelSelector';
import {
  FormProvider,
  Table,
  TableColumns,
  Tooltip,
  api,
  getEditablePropsForDictionaries,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { Button, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { Icon } from '@fleet/shared/mui';
import { InventoryModelBucket } from 'dto/inventory';
import {
  createOrUpdateInventoryModelBucket,
  getInventoryModel,
} from 'features/inventoryModel/inventoryModelActions';
import { useRowSelect } from 'react-table';
import { TransTableHead } from 'i18n/trans/table';
import { fetchInventoryBuckets } from 'features/inventoryModel/inventoryModelService';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { TransError } from 'i18n/trans/error';
import { renderToString } from 'react-dom/server';

export const InventoryModelBucketsAccordion: FC = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const currentModel = useSelector(currentInventoryModelSelector);
  const data = useMemo(() => currentModel?.buckets || [], [currentModel]);
  const [bucketOptions, setBucketOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const bucketOptionsMap = useMemo(
    () => new Map(bucketOptions.map(({ value, label }) => [value, label])),
    [bucketOptions]
  );
  const usedBuckets = useMemo(
    () => data.map(({ bucket }) => bucket.id),
    [data]
  );
  const availableBucketsOptions = useMemo(
    () => bucketOptions.filter(({ value }) => !usedBuckets.includes(value)),
    [bucketOptions, usedBuckets]
  );

  const fetchInventoryBucketOptions = useCallback(async () => {
    setBucketOptions(
      (
        await fetchInventoryBuckets({
          ownerId: currentModel!.owner.id,
          inventoryClassId: currentModel!.inventoryClass.id,
          limit: 1000,
        })
      ).map(({ id, code }) => ({ value: id, label: code }))
    );
  }, [currentModel]);

  useEffect(() => {
    if (currentModel?.owner) {
      fetchInventoryBucketOptions();
    }
  }, [
    currentModel?.owner,
    currentModel?.inventoryClass,
    fetchInventoryBucketOptions,
  ]);

  const numberArrayToOptions = (arr: Array<number>) =>
    arr.map((el) => ({ value: el, label: el.toString() }));

  const getOptionsFrom = useCallback(
    (
      startIndex: number,
      dataField: keyof Pick<InventoryModelBucket, 'branch' | 'nestingOrder'>
    ) => {
      const options = [startIndex];

      if (data.length) {
        data.forEach((el) => options.push(el[dataField]));
        options.push(Math.max(...options) + 1);
      }

      return numberArrayToOptions([...new Set(options)]);
    },
    [data]
  );

  const columns: TableColumns<InventoryModelBucket> = useMemo(
    () => [
      {
        id: 'bucket.id',
        accessor: ({ bucket }) => bucket?.id,
        Header: <TransTableHead i18nKey="inventoryBucket" />,
        type: 'select',
        editableProps: ({ value }) =>
          getEditablePropsForDictionaries({
            value,
            availableOptions: availableBucketsOptions,
            dictionary: bucketOptionsMap,
            isEnabled: true,
          }),
        wrapper: (field, cell) => {
          if (!cell.row.state.editable) {
            return (
              <Tooltip
                placement="top"
                content={cell.row.original.bucket?.description}
              >
                <span>{field}</span>
              </Tooltip>
            );
          }

          return field;
        },
      },
      {
        accessor: 'description',
        Header: <TransTableHead i18nKey="description" />,
        editableProps: {
          required: false,
        },
      },
      {
        accessor: 'percentage',
        Header: <TransTableHead i18nKey="percentage" />,
        editableProps: {
          type: 'number',
          validate: (value) => {
            const percentage = Number(value);

            return percentage && (percentage < 1 || percentage > 100)
              ? renderToString(
                  <TransError i18nKey="valueShouldBeBetweenOneAndHundred" />
                )
              : undefined;
          },
        },
      },
      {
        accessor: 'branch',
        Header: <TransTableHead i18nKey="branch" />,
        type: 'select',
        editableProps: {
          options: getOptionsFrom(0, 'branch'),
        },
      },
      {
        accessor: 'nestingOrder',
        Header: <TransTableHead i18nKey="nestingOrder" />,
        type: 'select',
        editableProps: {
          options: getOptionsFrom(1, 'nestingOrder'),
        },
      },
    ],
    [availableBucketsOptions, bucketOptionsMap, getOptionsFrom]
  );

  const { form } = useForm<{ rows: Array<InventoryModelBucket> }>({
    initialValues: {
      rows: data,
    },
  });

  const handleRowUpdate = useCallback(
    async ({ bucket, percentage, ...rest }: Partial<InventoryModelBucket>) => {
      await dispatch(
        createOrUpdateInventoryModelBucket({
          bucketId: bucket!.id,
          percentage: percentage! / 100,
          ...rest,
        })
      ).unwrap();
      alert.success(
        <TransAlert
          i18nKey={
            rest.id
              ? 'inventoryModelBucketUpdated'
              : 'inventoryModelBucketCreated'
          }
        />
      );
      dispatch(getInventoryModel(currentModel!.id));
    },
    [dispatch, currentModel, alert]
  );

  const table = useFormTable<InventoryModelBucket>(
    {
      data,
      columns,
      form,
      onRowUpdate: handleRowUpdate,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const handleRowsDeleted = useCallback(
    async (values: Array<InventoryModelBucket>) => {
      await api.post(`/models/${currentModel!.id}/buckets/bulk-delete`, {
        modelBucketIds: values.map((x) => x.id),
      });
      alert.success(<TransAlert i18nKey="inventoryModelBucketDeleted" />);
      dispatch(getInventoryModel(currentModel!.id));
    },
    [alert, dispatch, currentModel]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: handleRowsDeleted,
  });

  return (
    <FormProvider form={form}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        sx={{ mb: 1, mt: 1 }}
      >
        <Button
          startIcon={<Icon name="trash" size={20} />}
          variant="text"
          color="error"
          disabled={!table.selectedFlatRows.length}
          onClick={removeSelectedRows}
        >
          <TransButton i18nKey="deleteSelected" />
        </Button>
        <Button startIcon={<Icon name="plus" />} onClick={addRow}>
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        table={table}
        getHeaderGroupProps={{
          sx: { backgroundColor: 'common.white' },
        }}
      />
    </FormProvider>
  );
};
