import {
  Checkbox,
  ConfirmDeleteModal,
  Icon,
  SearchResult,
  Table,
  TableCaption,
  TableColumns,
  tableRowClickEventWrapper,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowActive,
  useTableRowHighlight,
} from '@fleet/shared';
import { Link } from 'react-router-dom';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import {
  Row,
  useFilters,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { InventoryModel } from 'dto/inventory';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { useHistory, useParams } from 'react-router-dom';
import { formatDate } from '@fleet/shared/utils/date';
import { InventoryModelSearchForm } from 'routes/InventoryConfiguration/InventoryModel/InventoryModelSearchForm';
import { useDispatch, useSelector } from 'store/utils';
import { inventoryModelLoadingSelector } from 'features/loading/loadingSelectors';
import {
  inventoryModelFilterSelector,
  inventoryModelsSelector,
} from 'features/inventoryModel/inventoryModelSelector';
import {
  deleteInventoryModel,
  getInventoryModels,
} from 'features/inventoryModel/inventoryModelActions';
import { TransField } from 'i18n/trans/field';
import { TransModal } from 'i18n/trans/modal';

const IS_DELETE_FUNCTIONALITY_HIDDEN = true; //This functionality is't fully implemented in the BE yet

export const InventoryModelTable: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(inventoryModelLoadingSelector);
  const models = useSelector(inventoryModelsSelector);
  const filter = useSelector(inventoryModelFilterSelector);
  const { open: isOpen, onOpen, onClose } = useModal();

  const data = useMemo(() => models?.items ?? [], [models?.items]);

  const link = useCallback(
    (row: Row<InventoryModel>) =>
      `/configuration/model/edit/${row.original.id}`,
    []
  );

  const columns: TableColumns<InventoryModel> = useMemo(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="modelName" />,
        Cell: ({ row, value }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {value}
          </Link>
        ),
      },
      {
        id: 'owner.id',
        accessor: ({ owner }) => owner?.name,
        Header: <TransTableHead i18nKey="owner" />,
      },
      {
        id: 'nestingType.id',
        accessor: ({ nestingType }) => nestingType?.name,
        Header: <TransTableHead i18nKey="nestingType" />,
      },
      {
        id: 'controlLevel.id',
        accessor: ({ controlLevel }) => controlLevel?.name,
        Header: <TransTableHead i18nKey="controlLevel" />,
      },
      {
        id: 'inventoryClass.id',
        accessor: ({ inventoryClass }) => inventoryClass?.name,
        Header: <TransTableHead i18nKey="inventoryClass" />,
      },
      {
        id: 'validFrom',
        accessor: ({ validity }) => formatDate(validity.from),
        Header: <TransTableHead i18nKey="validFrom" />,
      },
      {
        id: 'validTo',
        accessor: ({ validity }) => formatDate(validity.to),
        Header: <TransTableHead i18nKey="validTo" />,
      },
      {
        accessor: 'areControlsBidirectional',
        Header: <TransTableHead i18nKey="controlsBidirectional" />,
        Cell: (cell) =>
          cell.value ? (
            <Icon name="check" color="success" />
          ) : (
            <Icon name="close" color="error" />
          ),
        width: 100,
      },
      {
        accessor: 'isActive',
        Header: <TransTableHead i18nKey="isActive" />,
        Cell: (cell) =>
          cell.value ? (
            <Icon name="check" color="success" />
          ) : (
            <Icon name="close" color="error" />
          ),
        width: 80,
      },
    ],
    [link]
  );

  const getRowId = useCallback((row) => `${row.id!}`, []);

  const getPage = useCallback(
    (pageSize: number) => {
      if (models) {
        const { limit = pageSize, offset } = models;
        return offset / limit;
      }
      return 0;
    },
    [models]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      dispatch(getInventoryModels({ ...filter, ...paginationParams }));
    },
    [filter, dispatch]
  );

  const table = useTable<InventoryModel>(
    {
      data,
      columns,
      getRowId,
      pageCount: -1,
      total: models?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
        pageSize: filter.limit ?? state.pageSize,
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    useFilters,
    usePagination,
    useRowActive,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const {
    state: { selectedRowIds },
  } = table;

  useTableRowHighlight(id, table);

  const removeSelectedRows = useCallback(async () => {
    await Promise.all(
      Object.keys(selectedRowIds).map((id) =>
        dispatch(deleteInventoryModel(id))
      )
    );
    onClose();
    dispatch(getInventoryModels(filter));
  }, [selectedRowIds, onClose, dispatch, filter]);

  const handleActiveFilterToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      table.setFilter('isActive', e.target.checked || undefined),
    [table]
  );

  return (
    <>
      <InventoryModelSearchForm />
      <Divider />
      <SearchResult results={data.length} loading={loading}>
        <Table
          caption={
            <>
              <TableCaption>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Checkbox
                    size="small"
                    label={
                      <Typography
                        variant="body2"
                        color="text.primary"
                        component="span"
                      >
                        <TransField i18nKey="activeInventoryModels" />
                      </Typography>
                    }
                    onChange={handleActiveFilterToggle}
                    inline
                  />
                  {IS_DELETE_FUNCTIONALITY_HIDDEN ? null : (
                    <>
                      <Button
                        startIcon={<Icon name="trash" />}
                        sx={{ p: 0, whitespace: 'nowrap' }}
                        color="error"
                        onClick={onOpen}
                        disabled={
                          !Object.keys(table.state.selectedRowIds).length
                        }
                      >
                        <TransButton i18nKey="deleteSelected" />
                      </Button>
                      <ConfirmDeleteModal
                        handleDelete={removeSelectedRows}
                        title={
                          <TransModal i18nKey="inventoryModelDeletionTitle" />
                        }
                        description={
                          <TransModal i18nKey="inventoryModelDeletionDescription" />
                        }
                        isOpen={isOpen}
                        onClose={onClose}
                      />
                    </>
                  )}
                </Stack>
              </TableCaption>
            </>
          }
          table={table}
          getRowProps={(_, { row }) => ({
            sx: { cursor: 'pointer' },
            onClick: () => history.push(link(row)),
          })}
        />
      </SearchResult>
    </>
  );
};
