import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  AddButton,
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  makeClassificationOptions,
  SearchForm,
  SelectField,
  SelectOwnerField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Link } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import { InventoryBucketFilter, InventoryBucketValues } from 'dto/inventory';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { fetchInventoryClasses } from 'features/common/commonService';
import {
  getInventoryBuckets,
  setInventoryBucketFilter,
} from 'features/inventoryBucket/inventoryBucketActions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransNav } from 'i18n/trans/nav';
import { useDispatch, useSelector } from 'store/utils';
import { inventoryBucketFilterSelector } from 'features/inventoryBucket/InventoryBucketSelectors';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { ALLOWED_BUSINESS_ENTITY_ROLES } from 'dto/classification';
import { pick } from 'lodash';

interface InventoryBucketSearchFormProps {}

export const InventoryBucketSearchForm: FC<InventoryBucketSearchFormProps> =
  () => {
    const dispatch = useDispatch();
    const businessEntities = useSelector(businessEntitiesSelector);
    const currentBusinessEntityId = useSelector(
      currentBusinessEntityIdSelector
    );
    const [inventoryClassOptions, setInventoryClassOptions] = useState<
      Array<{ value: string; label: string }>
    >([]);
    const filter = useSelector(inventoryBucketFilterSelector);

    const onSubmit = useCallback(
      (values: Partial<InventoryBucketFilter>) => {
        formSubmit(async () => {
          dispatch(getInventoryBuckets({ ...values, offset: 0 }));
        });
      },
      [dispatch]
    );

    const defaultValues = useMemo<Partial<InventoryBucketValues>>(
      () => ({ ownerId: currentBusinessEntityId }),
      [currentBusinessEntityId]
    );

    const initialValues = useMemo<Partial<InventoryBucketValues>>(
      () => ({ ...defaultValues, ...filter }),
      [defaultValues, filter]
    );

    const { form, handleSubmit, values } = useForm<InventoryBucketFilter>({
      initialValues,
      onSubmit,
      subscription: { values: true },
    });

    const fetchInventoryClassOptions = useCallback(async () => {
      setInventoryClassOptions(
        makeClassificationOptions(await fetchInventoryClasses(values.ownerId))
      );
    }, [values.ownerId]);

    useEffect(() => {
      if (values.ownerId) {
        fetchInventoryClassOptions();
      }
    }, [fetchInventoryClassOptions, values.ownerId]);

    const handleReset = useCallback(() => {
      form.reset();
      dispatch(
        setInventoryBucketFilter({
          ...defaultValues,
          ...pick(filter, ['limit', 'offset']),
        })
      );
    }, [defaultValues, dispatch, filter, form]);

    return (
      <SearchForm
        title={<TransNav i18nKey="inventoryBucket" />}
        action={
          <AddButton component={Link} to="/configuration/bucket/create">
            <TransButton i18nKey="add" />
          </AddButton>
        }
      >
        <FormProvider form={form}>
          <form onSubmit={handleSubmit}>
            <Grid container columns={5} spacing={2}>
              <Grid item xs={1}>
                <SelectOwnerField
                  businessEntities={businessEntities}
                  allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
                  required={false}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs={1}>
                <TextField name="code" label={<TransField i18nKey="code" />} />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="inventoryClassId"
                  label={<TransField i18nKey="inventoryClass" />}
                  options={inventoryClassOptions}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" spacing={2}>
                  <FormControl label="&nbsp;">
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="text"
                      onClick={handleReset}
                    >
                      <TransButton i18nKey="resetFilters" />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button variant="contained" type="submit" icon="search">
                      <TransButton i18nKey="search" />
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </SearchForm>
    );
  };
