import { createTrans, TransKeys } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransSubtitle = createTrans({
  inventoryBuckets: (
    <Trans i18nKey="subTitle.inventoryBuckets" defaults="Inventory buckets" />
  ),
  search: <Trans i18nKey="subtitle.search" defaults="Search" />,
  searchResults: (
    <Trans i18nKey="subtitle.searchResults" defaults="Search results" />
  ),
});

export type TransSubtitleKeys = TransKeys<typeof TransSubtitle>;
