import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransModal = createTrans({
  inventoryBucketDeletionDescription: (
    <Trans
      i18nKey="modal.title.inventoryBucketDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected inventory bucket? It cannot be undone];(2-inf)[Are you sure you want to delete selected inventory buckets? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  inventoryBucketDeletionTitle: (
    <Trans
      i18nKey="modal.title.inventoryBucketDeletionTitle"
      defaults="Delete inventory bucket"
    />
  ),
  inventoryModelDeletionDescription: (
    <Trans
      i18nKey="modal.title.inventoryModelDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected inventory model? It cannot be undone];(2-inf)[Are you sure you want to delete selected inventory models? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  inventoryModelDeletionTitle: (
    <Trans
      i18nKey="modal.title.inventoryModelDeletionTitle"
      defaults="Delete inventory model"
    />
  ),
  inventoryModelInventoryBucketDeletionDescription: (
    <Trans
      i18nKey="modal.title.inventoryModelInventoryBucketDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected inventory model inventory bucket? It cannot be undone];(2-inf)[Are you sure you want to delete selected inventory model inventory buckets? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  inventoryModelInventoryBucketDeletionTitle: (
    <Trans
      i18nKey="modal.title.inventoryModelInventoryBucketDeletionTitle"
      defaults="Delete inventory model inventory bucket"
    />
  ),
  localizationDeletionDescription: (
    <Trans
      i18nKey="modal.title.localizationDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected localization? It cannot be undone];(2-inf)[Are you sure you want to delete selected localizations? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  localizationDeletionTitle: (
    <Trans
      i18nKey="modal.title.localizationDeletionTitle"
      defaults="Delete localization"
    />
  ),
});
