import {
  Drawer,
  FormProvider,
  formSubmit,
  makeClassificationOptions,
  SelectField,
  SelectOwnerField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Icon, Tooltip } from '@fleet/shared/mui';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { InventoryBucketValues } from 'dto/inventory';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import {
  currentInventoryBucketSelector,
  inventoryBucketFilterSelector,
} from 'features/inventoryBucket/InventoryBucketSelectors';
import {
  createOrUpdateInventoryBucket,
  getInventoryBucket,
  getInventoryBuckets,
  setInventoryBucket,
} from 'features/inventoryBucket/inventoryBucketActions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { fetchInventoryClasses } from 'features/common/commonService';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { ALLOWED_BUSINESS_ENTITY_ROLES } from 'dto/classification';

interface InventoryBucketDetailsProps {}

export const InventoryBucketDetails: FC<InventoryBucketDetailsProps> = () => {
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const businessEntities = useSelector(businessEntitiesSelector);
  const currentInventoryBucket = useSelector(currentInventoryBucketSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const [inventoryClassOptions, setInventoryClassOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const filter = useSelector(inventoryBucketFilterSelector);

  useEffect(() => {
    if (action === 'edit' && id) {
      dispatch(getInventoryBucket(id));
    }

    return () => {
      dispatch(setInventoryBucket());
    };
  }, [action, dispatch, id]);

  const closeDrawer = useCallback(() => {
    history.replace('/configuration/bucket');
  }, [history]);

  const initialValues = useMemo<Partial<InventoryBucketValues>>(() => {
    if (!currentInventoryBucket) {
      return {
        ownerId: currentBusinessEntityId,
      };
    }

    const { inventoryClass, owner, ...rest } = currentInventoryBucket;

    return {
      ...rest,
      inventoryClassId: inventoryClass.id,
      ownerId: owner.id,
    };
  }, [currentBusinessEntityId, currentInventoryBucket]);

  const onSubmit = useCallback(
    async (values: InventoryBucketValues) =>
      formSubmit(async () => {
        const data = await dispatch(
          createOrUpdateInventoryBucket(values)
        ).unwrap();

        alert.success(
          <TransAlert
            i18nKey={
              values.id ? 'inventoryBucketUpdated' : 'inventoryBucketCreated'
            }
          />
        );

        if (!values.id) {
          history.push(`/configuration/bucket/edit/${data.id}`);
        } else {
          dispatch(getInventoryBucket(data.id));
        }

        dispatch(getInventoryBuckets(filter));
      }),
    [dispatch, alert, history, filter]
  );

  const { form, handleSubmit, submitting, values } =
    useForm<InventoryBucketValues>({
      initialValues,
      onSubmit,
      subscription: { submitting: true, values: true },
    });

  const fetchInventoryClassOptions = useCallback(async () => {
    setInventoryClassOptions(
      makeClassificationOptions(await fetchInventoryClasses(values.ownerId))
    );
  }, [values.ownerId]);

  useEffect(() => {
    if (values.ownerId) {
      fetchInventoryClassOptions();
    }
  }, [fetchInventoryClassOptions, values.ownerId]);

  return (
    <Drawer anchor="right" elevation={0} onClose={closeDrawer} open>
      <FormProvider form={form}>
        <Stack
          sx={{
            height: '100%',
            width: '424px !important',
            minWidth: '0 !important',
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <CardHeader
            sx={{ pb: 3 }}
            title={
              <Typography variant="subtitle">
                {id ? (
                  <TransTitle i18nKey="inventoryBucket" />
                ) : (
                  <TransTitle i18nKey="newInventoryBucket" />
                )}
              </Typography>
            }
            action={
              <IconButton aria-label="close" onClick={closeDrawer}>
                <Tooltip content={<TransButton i18nKey="close" />} delay={500}>
                  <Icon name="close" size={24} />
                </Tooltip>
              </IconButton>
            }
          />
          <CardContent sx={{ flex: 1, py: 0, overflowY: 'auto' }}>
            <SelectOwnerField
              businessEntities={businessEntities}
              allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
              disabled={!!currentInventoryBucket?.owner.id}
              margin="dense"
            />
            <TextField
              label={<TransField i18nKey="code" />}
              name="code"
              required
              margin="dense"
            />
            <SelectField
              name="inventoryClassId"
              label={<TransField i18nKey="inventoryClass" />}
              options={inventoryClassOptions}
              required
              margin="dense"
            />
            <TextField
              label={<TransField i18nKey="description" />}
              name="description"
              margin="dense"
            />
          </CardContent>
          <CardActions
            sx={{
              padding: 3,
              justifyContent: 'flex-end',
              boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Button variant="text" color="primary" onClick={closeDrawer}>
              <TransButton i18nKey="cancel" />
            </Button>
            {id ? (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<Icon name="check" />}
              >
                <TransButton i18nKey="save" disabled={submitting} />
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
                startIcon={<Icon name="plus" />}
              >
                <TransButton i18nKey="create" />
              </Button>
            )}
          </CardActions>
        </Stack>
      </FormProvider>
    </Drawer>
  );
};
