import { FC, useCallback, useMemo } from 'react';
import { InventoryBucketSearchForm } from './InventoryBucketSearchForm';
import {
  ConfirmDeleteModal,
  Icon,
  SearchResult,
  Table,
  TableCaption,
  TableColumns,
  tableRowClickEventWrapper,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowActive,
  useTableRowHighlight,
} from '@fleet/shared';
import { useDispatch, useSelector } from 'store/utils';
import {
  inventoryBucketFilterSelector,
  inventoryBucketsSelector,
} from 'features/inventoryBucket/InventoryBucketSelectors';
import { inventoryBucketLoadingSelector } from 'features/loading/loadingSelectors';
import { Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { TransButton } from 'i18n/trans/button';
import { InventoryBucket } from 'dto/inventory';
import { TransTableHead } from 'i18n/trans/table';
import { useHistory, useParams } from 'react-router-dom';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import {
  deleteInventoryBucket,
  getInventoryBuckets,
} from 'features/inventoryBucket/inventoryBucketActions';
import { Row, usePagination, useRowSelect, useTable } from 'react-table';
import { TransModal } from 'i18n/trans/modal';

const IS_DELETE_FUNCTIONALITY_HIDDEN = true; //This functionality isn't fully implemented in the BE yet

interface InventoryBucketTableProps {}

export const InventoryBucketTable: FC<InventoryBucketTableProps> = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const inventoryBuckets = useSelector(inventoryBucketsSelector);
  const loading = useSelector(inventoryBucketLoadingSelector);
  const filter = useSelector(inventoryBucketFilterSelector);
  const { open: isOpen, onOpen, onClose } = useModal();

  const data = useMemo(
    () => inventoryBuckets?.items ?? [],
    [inventoryBuckets?.items]
  );

  const link = useCallback(
    (row: Row<InventoryBucket>) =>
      `/configuration/bucket/edit/${row.original.id}`,
    []
  );

  const columns: TableColumns<InventoryBucket> = useMemo(
    () => [
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
        Cell: ({ row }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {row.original.code}
          </Link>
        ),
      },
      {
        accessor: 'inventoryClass',
        Header: <TransTableHead i18nKey="inventoryClass" />,
        Cell: ({ value }) => value.name,
      },
      {
        id: 'owner.id',
        accessor: ({ owner }) => owner?.name,
        Header: <TransTableHead i18nKey="owner" />,
      },
      {
        accessor: 'description',
        Header: <TransTableHead i18nKey="description" />,
      },
    ],
    [link]
  );

  const getRowId = useCallback((row) => `${row.id!}`, []);

  const getPage = useCallback(
    (pageSize: number) => {
      if (inventoryBuckets) {
        const { limit = pageSize, offset } = inventoryBuckets;
        return offset / limit;
      }
      return 0;
    },
    [inventoryBuckets]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      dispatch(getInventoryBuckets({ ...filter, ...paginationParams }));
    },
    [filter, dispatch]
  );

  const table = useTable<InventoryBucket>(
    {
      data,
      columns,
      getRowId,
      pageCount: -1,
      total: inventoryBuckets?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
        pageSize: filter.limit ?? state.pageSize,
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const {
    state: { selectedRowIds },
  } = table;

  useTableRowHighlight(id, table);

  const removeSelectedRows = useCallback(async () => {
    await Promise.all(
      Object.keys(selectedRowIds).map((id) =>
        dispatch(deleteInventoryBucket(id))
      )
    );

    onClose();
    dispatch(getInventoryBuckets(filter));
  }, [selectedRowIds, dispatch, filter, onClose]);

  return (
    <>
      <InventoryBucketSearchForm />
      <Divider />
      <SearchResult results={data.length} loading={loading}>
        <Table
          caption={
            IS_DELETE_FUNCTIONALITY_HIDDEN ? null : (
              <TableCaption>
                <Button
                  hidden={true}
                  startIcon={<Icon name="trash" />}
                  sx={{ p: 0, whitespace: 'nowrap' }}
                  color="error"
                  onClick={onOpen}
                  disabled={!Object.keys(table.state.selectedRowIds).length}
                >
                  <TransButton i18nKey="deleteSelected" />
                </Button>
                <ConfirmDeleteModal
                  handleDelete={removeSelectedRows}
                  title={<TransModal i18nKey="inventoryBucketDeletionTitle" />}
                  description={
                    <TransModal i18nKey="inventoryBucketDeletionDescription" />
                  }
                  isOpen={isOpen}
                  onClose={onClose}
                />
              </TableCaption>
            )
          }
          table={table}
          getRowProps={(_, { row }) => ({
            sx: { cursor: 'pointer' },
            onClick: () => history.push(link(row)),
          })}
        />
      </SearchResult>
    </>
  );
};
